<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="60px"
              max-width="60px"
              alt="logo"
              contain
              class="me-3"
            />

<!--            <h2 class="text-2xl font-weight-semibold">-->
<!--              {{ appName }}-->
<!--            </h2>-->
          </router-link>
        </v-card-title>

        <!-- title -->
        <!--        <v-card-text>-->
        <!--          <p class="text-2xl font-weight-semibold text&#45;&#45;primary mb-2">-->
        <!--            Adventure starts here 🚀-->
        <!--          </p>-->
        <!--          <p class="mb-2">-->
        <!--            Make your app management easy and fun!-->
        <!--          </p>-->
        <!--        </v-card-text>-->

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="registerForm"
            @submit.prevent="handleFormSubmit"
            lazy-validation
          >

<!--            <v-row class="justify-center mb-2">-->
<!--              <v-col class="d-flex justify-end">-->
<!--                <span :class="{'primary&#45;&#45;text': !seller}" class="font-weight-semibold">{{ $t('Buyer') }}</span>-->
<!--              </v-col>-->
<!--              <v-col cols="2">-->
<!--                <v-switch-->
<!--                    v-model="seller"-->
<!--                    hide-details-->
<!--                    class="primary&#45;&#45;text mt-0 pt-0"-->
<!--                ></v-switch>-->
<!--              </v-col>-->
<!--              <v-col>-->
<!--                <span :class="{'primary&#45;&#45;text': seller}" class="font-weight-semibold">{{ $t('Vendor') }}</span>-->
<!--              </v-col>-->
<!--            </v-row>-->

            <v-text-field
              v-model="username"
              outlined
              label="Full name"
              :error-messages="errorMessages.username"
              :rules="[validators.required, validators.alphaWithSpaceValidator]"
              placeholder="Full name"
              hide-details="auto"
              class="mb-6"
            />
            <v-text-field
              v-model="email"
              outlined
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              label="Email"
              placeholder="Email"
              hide-details="auto"
              class="mb-6"
            />
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :error-messages="errorMessages.password"
              placeholder="Password"
              :rules="[validators.required, validators.passwordValidator]"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              hide-details="auto"
              class="mb-2"
              @click:append="isPasswordVisible = !isPasswordVisible"
            />
            <v-text-field
              v-model="passwordConfirm"
              outlined
              :type="isPasswordConfirmVisible ? 'text' : 'password'"
              label="Confirm Password"
              :error-messages="errorMessages.passwordConfirm"
              placeholder="Confirm Password"
              :rules="[validators.required, validators.passwordValidator, validators.confirmedValidator(passwordConfirm, password)]"
              :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              hide-details="auto"
              class="mb-2"
              @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
            />
            <v-checkbox
              :class="{'mt-0': seller}"
              v-model="seller"
              label="I want to sell OA deals"
            />
            <v-expand-transition>
              <div v-show="seller">
                <v-textarea
                  min-height="100"
                  v-model="sellerDescription"
                  :rules="sellerDescriptionRules"
                  :label="$t('Describe your research experience.')"
                  :placeholder="$t('Describe your research experience.')"
                  outlined
                />
                <v-checkbox
                  class="mt-0"
                  v-model="sellerAgreement"
                  :rules="sellerAgreementRules"
                  :true-value="1"
                  :false-value="0"
                >
                  <template #label>
                    <div class="d-flex align-center flex-wrap">
                      <span class="me-2">I accept the</span><a @click.stop="" target="_blank" href="https://www.sellerassistant.deals/supplier-agreement">seller agreement</a>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </v-expand-transition>
            <v-checkbox
              class="mt-0"
              v-model="privacyPolicy"
              :true-value="1"
              :false-value="0"
              :rules="[v => !!v || 'You must agree to continue']"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to <a @click.stop="" target="_blank" href="https://www.sellerassistant.deals/privacy">privacy policy</a> &amp; <a href="https://www.sellerassistant.deals/terms">terms</a></span>
                </div>
              </template>
            </v-checkbox>
            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
            >
              {{ $t('Sign Up') }}
            </v-btn>
          </v-form>
        </v-card-text>
        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <p class="mb-0 me-2">
            Already have an account?
          </p>
          <router-link :to="{name:'auth-login'}">
            Sign in instead
          </router-link>
        </v-card-text>
      </v-card>
    </div>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    />
    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline} from '@mdi/js'
import {computed, getCurrentInstance, ref} from '@vue/composition-api'
import themeConfig from '@themeConfig'
import {getCookie} from "@/plugins/helpers"
import axios from "@axios";
import {useRouter} from "@core/utils";
import {
  alphaValidator,
  alphaWithSpaceValidator,
  confirmedValidator,
  emailValidator,
  passwordValidator,
  required
} from "@core/utils/validation";
import store from "@/store";
import * as intercom from "@/plugins/tracking/intercom";

export default {
  setup() {
    // Template Refs
    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    const {router, route} = useRouter()

    const isPasswordConfirmVisible = ref(false)
    const isPasswordVisible = ref(false)
    const username = ref('')
    const promRef = ref(getCookie('_fprom_ref'))
    const promTid = ref(getCookie('_fprom_tid'))
    const email = ref('')
    const password = ref('')
    const passwordConfirm = ref('')
    const errorMessages = ref([])
    const seller = ref(!!route.value.query.seller)
    const sellerDescription = ref('')
    const sellerAgreement = ref(0)
    const privacyPolicy = ref(0)
    const sellerDescriptionRules = [v => !seller.value || !!v || 'Seller info required', v => !seller.value || v.length > 20 || 'Minimal length of characters is 20']
    const sellerAgreementRules = [v => !seller.value || !!v || 'Seller agreement required']

    const handleFormSubmit = () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      axios
          .post('/auth/register', {
            name: username.value,
            email: email.value,
            password: password.value,
            password_confirm: passwordConfirm.value,
            seller: seller.value,
            note: sellerDescription.value,
            prom_ref: promRef.value,
            prom_tid: promTid.value,
          })
          .then(response => {
            const {token} = response.data

            // ? Set access token in localStorage so axios interceptor can use it
            // Axios Interceptors: https://github.com/axios/axios#interceptors
            localStorage.setItem('accessToken', token)

            return response
          })
          .then(() => {
            axios.get('/auth/me').then(response => {
              const user = response.data.data
              const {ability: userAbility} = user

              // Set user ability
              // ? https://casl.js.org/v5/en/guide/intro#update-rules
              vm.$ability.update(userAbility)

              // ? Set user's ability in localStorage for Access Control
              localStorage.setItem('userAbility', JSON.stringify(userAbility))

              // ? We will store `userAbility` in localStorage separate from userData
              // ? Hence, we are just removing it from user object
              delete user.ability

              // ? Set user's data in localStorage for UI/Other purpose
              localStorage.setItem('userData', JSON.stringify(user))

              // init intercom
              intercom.initIntercom(vm.$intercom)

              // ? On success redirect to home
              if(route.value.query.route && route.value.query.route === "checkout"){
                router.push({name: 'checkout'})
              } else if (seller.value) {
                router.push({name: 'user-account'})
              } else {
                router.push('/')
              }
            })
          })
          .catch(error => {
            vm.$notify({
              group: 'deals',
              title: 'Sign up error',
              type: 'error',
              text: vm.$t(error.response.data.message),
              max: 5
            })
            const err = error.response.data.errors
            errorMessages.value = {
              username: err.name ? err.name[0] : '',
              email: err.email ? err.email[0] : '',
              password: err.password ? err.password[0] : '',
              passwordConfirm: err.password_confirm ? err.password_confirm[0] : ''
            }
          })
    }

    return {
      isPasswordConfirmVisible,
      isPasswordVisible,
      username,
      email,
      password,
      passwordConfirm,
      errorMessages,
      sellerAgreementRules,
      sellerAgreement,
      seller,
      sellerDescription,
      privacyPolicy,
      sellerDescriptionRules,
      handleFormSubmit,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        confirmedValidator,
        alphaWithSpaceValidator,
      },
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      // Template Refs
      registerForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';

.v-input--selection-controls__input {
  color: var(--v-primary-base) !important;
}
</style>
